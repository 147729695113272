require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

import Swal from 'sweetalert2';

import 'datatables.net-dt/js/dataTables.dataTables.js';

$( document ).ajaxError(function( event, jqxhr, settings, thrownError ) {
    alert("Session expired. You'll be take to the login page");
    location.href = "/login";
});
